<template>
  <div :class="[$style.detail, 'flex flex-col']">
    <DetailHeader :headerData="headerData" />
    <div class="flex-1">
      <a-tabs
        class="h-full"
        :default-active-key="activeKey"
        :tabBarStyle="{ margin: '10px 20px' }"
        @change="changeTab"
      >
        <a-tab-pane key="snap-record" tab="抓拍记录">
          <pagination-table
            :columns="tableConfig.snapRecord.columns"
            :api="tableConfig.snapRecord.api"
            row-key="pkId"
            :scroll="{ x: '100%', y: 'max-content' }"
          ></pagination-table>
        </a-tab-pane>
        <a-tab-pane key="camera" tab="摄像头">
          <pagination-table
            ref="cameraTableRef"
            :columns="tableConfig.camera.columns"
            :api="tableConfig.camera.api"
            row-key="pkId"
            :scroll="{ x: '100%', y: 'max-content' }"
          ></pagination-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import DetailHeader from '@/views/safety-device/component/detail-header';
import moment from 'moment';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '../component/safety-utils';
import PaginationTable from '@/components/pagination-table';
import {
  getSnapRecord,
  getCameraRecord,
  setCamera,
  delCamera,
} from '@/services/device-manage/device';
import EditTableIndex from './components/edit-table.vue';
import { createFormModal } from '@triascloud/x-components';
import Tip from '@/components/tip';
import OssImage from '@/views/screen/components/oss-image.vue';

@Component({
  components: {
    DetailHeader,
    PaginationTable,
  },
})
export default class LiftDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) detailData;

  deviceGroup = deviceTypeEnum[pageTypeEnum.aiBox].deviceGroup;
  headerData = {};
  mounted() {
    this.initHeadData();
  }

  get tableConfig() {
    return {
      snapRecord: {
        columns: [
          {
            align: 'left',
            title: '摄像头名称',
            dataIndex: 'cameraName',
            ellipsis: true,
            width: 150,
          },
          {
            align: 'left',
            title: '摄像头编号',
            dataIndex: 'code',
            ellipsis: true,
            width: 120,
          },
          {
            align: 'left',
            title: '报警时间',
            dataIndex: 'alarmTime',
            ellipsis: true,
            width: 200,
            customRender: text => moment(text).format('YYYY-MM-DD HH:mm:ss'),
          },
          {
            align: 'left',
            title: '报警类型',
            dataIndex: 'alarmTypeName',
            ellipsis: true,
            width: 150,
          },
          {
            align: 'left',
            title: '报警图片',
            dataIndex: 'alarmPhoto',
            width: 150,
            customRender: text => (
              <OssImage basePath="/oss/iot/oss" ossPath={text} />
            ),
          },
        ],
        api: async ({ current, size }) => {
          const res = await getSnapRecord(
            {
              current,
              size,
              pkId: this.deviceId,
            },
            this.deviceGroup,
          );
          return res;
        },
      },
      camera: {
        columns: [
          {
            align: 'left',
            title: '摄像头编号',
            dataIndex: 'cameraCode',
            ellipsis: true,
          },
          {
            align: 'left',
            title: '摄像头名称',
            dataIndex: 'cameraName',
            customRender: (text, record) => {
              const el = (
                <EditTableIndex
                  value={record.cameraName}
                  onSave={async val => {
                    await setCamera(
                      {
                        cameraName: val,
                        cameraCode: record.cameraCode,
                        deviceId: this.deviceId,
                      },
                      this.deviceGroup,
                    );
                    record.cameraName = val;
                  }}
                />
              );

              return el;
            },
          },
          {
            title: this.$t('common.operation'),
            // fixed: 'right',
            key: 'action',
            customRender: (_text, record) => {
              const actions = [
                {
                  name: this.$t('common.delete'),
                  handle: async () => {
                    const text = this.$t('safety.sureDeleteDevice');
                    const tips = this.$t('safety.deleteDeviceTips');
                    try {
                      await createFormModal(
                        () => (
                          <Tip iconStyle={{ padding: '0 0 22px' }}>
                            <template slot="txt">
                              <span style="color: #F72F2F">{text}</span>
                            </template>
                            <span slot="subTxt">{tips}</span>
                          </Tip>
                        ),
                        {
                          width: '442px',
                          title: this.$t('safety.importanceTips'),
                          onOk: async () => {
                            try {
                              await delCamera(
                                {
                                  cameraCode: record.cameraCode,
                                  deviceId: this.deviceId,
                                },
                                this.deviceGroup,
                              );
                              const str = this.$t('safety.deleteSuccess');
                              this.$message.success(str);
                              this.$refs.cameraTableRef.refresh();
                            } catch {
                              return false;
                            }
                          }, // 解绑 删除
                        },
                      );
                    } catch {
                      return false;
                    }
                  },
                },
              ];

              return (
                <div class={this.$style.action}>
                  {actions.map(action => (
                    <div onClick={action.handle}>{action.name}</div>
                  ))}
                </div>
              );
            },
          },
        ],
        api: async ({ current, size }) => {
          const res = await getCameraRecord(
            {
              current,
              size,
              pkId: this.deviceId,
            },
            this.deviceGroup,
          );
          return res;
        },
      },
    };
  }

  created() {}
  alarmCount = 0;
  get deviceId() {
    return this.detailData.pkId;
  }

  initHeadData() {
    const data = this.detailData;
    this.headerData = {
      deviceName: data.deviceName,
      deviceCode: data.deviceCode,
      bindTime: data.bindTime,
      projectName: data.projectName,
      remark: data.remark,
    };
  }
  activeKey = 'device-status';
  changeTab(activeKey) {
    this.activeKey = activeKey;
  }
  formateTime(t) {
    return t ? moment(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }
}
</script>

<style lang="less" module>
@import '../component/safety.less';
.action {
  display: flex;
  gap: 20px;
  width: max-content;
  flex-wrap: nowrap;
  div {
    cursor: pointer;
    color: var(--primary);
  }
}
:global(.ant-tabs-content) {
  height: calc(100% - 70px);
}
.alarmPhoto {
  aspect-ratio: 16 / 9;
  height: 100%;
  padding: 5px 0;
  box-sizing: border-box;
  cursor: pointer;
}
:global(.detail td) {
  height: 58px;
}
</style>
