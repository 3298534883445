<template>
  <div class="flex items-center gap-8">
    <template v-if="editable">
      <a-input v-model="inputValue"></a-input>
      <a-icon type="check" @click="check" />
    </template>
    <template v-else>
      <div>{{ value }}</div>
      <a-icon type="edit" @click="edit" />
    </template>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component()
export default class EditTableIndex extends Vue {
  @Prop({ type: String, default: '' }) value;

  @Watch('value', { immediate: true })
  valueChange(val) {
    this.inputValue = val;
  }
  editable = false;

  inputValue = '';

  edit() {
    this.editable = true;
  }

  check() {
    this.$emit('save', this.inputValue);
    this.editable = false;
  }
}
</script>
